import { useEffect, memo, useState } from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

import { useRouter } from 'next/router';

import ErrorNotification from 'src/components/ErrorNotification';
import {
  A,
  ButtonTextBig,
  Caption,
  H2,
  Text1,
  HHero,
} from 'src/components/Text';
import { RoutePath } from 'src/constants/route-path';
import { StepProps } from 'src/containers/Quiz/models';

import { getCookie } from 'src/utils/cookie';

import {
  GoalBtn,
  GoalBtns,
  PrivacyCheckboxSVG,
  PrivacyWrapper,
  StepApps,
  StepChoices,
  StepChoicesContent,
  StepWrapper,
  EmailConfirmCheckboxSVG,
  EmailConfirmWrapper,
} from './diet-hacker-styled';

function DietHacker({
  data,
  onSubmit,
  showEmailCheckbox,
}: StepProps): JSX.Element {
  const router = useRouter();
  const { t } = useTranslation('quiz');

  const [privacyChecked, togglePrivacy] = useState(true);
  const [emailChecked, toggleEmail] = useState(true);

  useEffect(() => {
    if (Object.values(router.query).length <= 2) {
      toggleEmail(false);
      togglePrivacy(false);
    }
  }, []);

  return (
    <StepWrapper>
      <StepApps />

      <StepChoices>
        <StepChoicesContent>
          <HHero className="md-only">{data.title}</HHero>
          <H2 className="sm-only">{data.title}</H2>
          {data.subtitle && <Text1>{data.subtitle}</Text1>}
          <GoalBtns>
            {data.nav.map((nav): JSX.Element[] =>
              data.selects[nav.id].options.map(
                (option): JSX.Element => (
                  <GoalBtn
                    disabled={!privacyChecked}
                    key={option.id}
                    type="button"
                    onClick={
                      privacyChecked
                        ? (): void =>
                            onSubmit({
                              formValue: { [option.id]: option.value },
                            })
                        : undefined
                    }
                  >
                    <ButtonTextBig>{option.title}</ButtonTextBig>
                  </GoalBtn>
                )
              )
            )}
          </GoalBtns>
          <PrivacyWrapper>
            <PrivacyCheckboxSVG
              className={privacyChecked ? 'checked' : ''}
              onClick={(): void => {
                togglePrivacy(!privacyChecked);
              }}
              role="button"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-label={t('goal.agreement')}
            >
              <path d="M9 11L12 14L22 4" />
              <path d="M21 12V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16" />
              <rect x="3" y="3" width="18" height="18" rx="3" />
            </PrivacyCheckboxSVG>
            <Caption>
              {t('goal.agreement')}{' '}
              <Link
                href={`${RoutePath.termsOfService}`}
                passHref
                prefetch={false}
              >
                <A>{t('goal.terms')}</A>
              </Link>
              ,{' '}
              <Link
                href={`${RoutePath.privacyPolicy}`}
                passHref
                prefetch={false}
              >
                <A>{t('goal.privacy')}</A>
              </Link>
              ,{' '}
              <Link
                href={`${RoutePath.subscriptionTerms}`}
                passHref
                prefetch={false}
              >
                <A>{t('goal.subscription')}</A>
              </Link>
              {', '}
              <Link
                href={`${RoutePath.cookiePolicy}`}
                passHref
                prefetch={false}
              >
                <A>{t('goal.cookiePolicy')}</A>
              </Link>
            </Caption>
          </PrivacyWrapper>
          {showEmailCheckbox && (
            <EmailConfirmWrapper>
              <EmailConfirmCheckboxSVG
                className={emailChecked ? 'checked' : ''}
                onClick={(): void => {
                  toggleEmail(!emailChecked);
                }}
                role="button"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-label={t('goal.email')}
              >
                <path d="M9 11L12 14L22 4" />
                <path d="M21 12V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16" />
                <rect x="3" y="3" width="18" height="18" rx="3" />
              </EmailConfirmCheckboxSVG>
              <Caption>
                {t('goal.email')}
                {process.env.NEXT_PUBLIC_MIRROR_TITLE || 'Unimeal'}{' '}
                {t('goal.emailEnd')}
              </Caption>
            </EmailConfirmWrapper>
          )}
          {!privacyChecked && (
            <ErrorNotification>{t('goal.error')}</ErrorNotification>
          )}
        </StepChoicesContent>
      </StepChoices>
    </StepWrapper>
  );
}

export default memo(DietHacker);
